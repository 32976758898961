import {getRequest, postRequest, putRequest, deleteRequest} from '@/utils/request'

// 添加/编辑发票
export const invoiceSave = (params) => {
    return postRequest('invoice/save', params)
}
// 用户发票列表
export const invoiceInvoice = (params) => {
    return putRequest('invoice/invoice', params)
}
// 发票详情
export const invoiceDetail = (params) => {
    return getRequest('invoice/detail/' + params)
}
// 设置默认发票
export const setDefault = (params) => {
    return postRequest('invoice/set_default/' + params)
}
// 删除发票
export const delInvoice = (params) => {
    return deleteRequest('invoice/del/' + params)
}
// 订单申请发票
export const makeUpInvoice = (params) => {
    return postRequest('invoice/order/make_up_invoice', params)
}
// 订单申请开票记录
export const invoiceList = (params) => {
    return putRequest('invoice/order/invoice_list', params)
}
// 订单发票修改
export const editOrderInvoice = (params) => {
    return postRequest('invoice/order/edit_make_up_invoice', params)
}
// 批量收货（pc 和小程序）
export const batchTaskOrder = (params) => {
    return postRequest('order/batchTaskOrder', params)
}